.tloris {
    stroke: #FFF !important;
    svg {
        width: 100%;
    }
    polyline {
        stroke: #FFF !important;
    }
    polygon {
        stroke: #FFF !important;
    }
    path {
        stroke: #FFF !important;
    }
    rect {
        stroke: #FFF !important;
    }
    line {
        stroke: #FFF !important;
    }
}