// Blocks can have a dark or light BG color, change text color accordingly
.block-tx-dark { color: #333; }
.block-tx-light { color: #fff; }


.block-multi-col__col-title {
    margin: 0 0 2em;
    font-family: "gt-std-bold";
    font-size: 18px;
    font-weight: bold;
}
.block-multi-col__col-symbol {
    margin-bottom: .5em;
    font-size: 48px;
    line-height: 1.35;
    font-family: "gt-mono";
    font-weight: normal;
}
.block-multi-col__col-img {
    display: block;
    margin-top: 48px;
    max-width: 100%;
}

.block-video {
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #fff;
}
.block-video__title {
    font-size: 48px;
    margin-bottom: 75px;
    font-family: "gt-std";
    font-weight: normal;
}
