html,
body {
    color: $brand-gray;
}
body {
    position: relative;
    min-height: 100vh;
}

.page {
    .main,
    .container-fluid {
        padding-left: 0px;
        padding-right: 0px;
    }
}
h2 {
    font-size: 48px;
    font-family: gt-std;
    @include media-breakpoint-down(md) {
        font-size: 42px;
    }
    a {
        color: $brand-gray;
        &:hover {
            color: $brand-gray;
        }
    }
}
p,
ul li,
ol li {
    font-size: 18px;
    font-family: gt-std;
    @include media-breakpoint-down(md) {
        font-size: 16px;
    }
}
p {
    line-height: 1.5;
}
ul li,
ol li {
    line-height: 1.5;
    margin-bottom: 15px;
}
.parallax-spacer {
    margin-top: 500px;
}
.top-z-index {
    z-index: 999;
}
.avoid-clicks {
  pointer-events: none;
}
.blog .posts-navigation {
    display: none;
}
