.section.masonry-grid {
    background-color: #FFF;
    padding-top: 150px;
    .grid {
        .grid-sizer,
        .grid-item { 
            width: 33%;
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
            img {
                width: 100%;
                padding: 10px;
            }
            .grid-text {
                padding: 10px;
                min-height: 245px;
                display: block;
                .quote-mark {
                    width: 50px;
                    padding: 0px;
                    filter: invert(20%);
                }
                p {
                    color: #333;
                    &.quote {
                        font-size: 18px;
                        font-style: italic;
                    }
                    &.author {
                        text-transform: uppercase;
                        color: $brand-brown;
                        font-size: 16px;
                        font-family: "gt-mono-bold";
                    }
                }
                @include media-breakpoint-down(lg) {
                    min-height: 195px;
                    .quote-mark {
                        width: 48px;
                    }
                    p {
                        &.quote {
                            font-size: 18px;
                        }
                        &.author {
                            font-size: 16px;
                        }
                    }
                }
                @include media-breakpoint-down(md) {
                    min-height: 170px;
                    .quote-mark {
                        width: 40px;
                    }
                    p {
                        &.quote {
                            font-size: 16px;
                        }
                        &.author {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .grid-item--width2 { 
            width: 66%;
            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }
    }
}