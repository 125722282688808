.widget.widget_categories {
    h2 {
        font-size: 24px;
    }
    ul li {
        margin-bottom: 0px;
        a {
            color: $brand-gray;
            font-size: 16px;
        }
    }
    .children {
        padding-left: 15px;
    }
}