.related-item {
    margin-bottom: 25px;
    a {
        text-decoration: none;
        &:hover {
            .related-img {
                filter: none !important;
            }
        }
    }
    .related-img {
        width: 100%;
        transition: all .2s ease-in-out;
    }
    span {
        font-family: gt-std;
        display: block;
    }
    .house-title {
        color: #333;
        font-size: 18px;
    }
    .house-details {
        font-size: 12px;
        color: #998675;
    }
}