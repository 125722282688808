.slide-menu {
    position: fixed;
    top: 0px;
    right: -320px;
    height: 100vh;
    width: 320px;
    color: #FFF;
    padding: 15px;
    font-family: "gt-std";
    z-index: 1010;
    transition: all 0.3s ease-in-out;
    .menu-header {
        .close-btn {
            filter: invert(100%);
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
        }
    }
    &.main-menu {
        right: -320px;
        background-color: $brand-brown;
        .menu-header {
            a.language {
                display: inline-block;
                color: #FFF;
                margin-right: 10px;
                font-size: 18px;
                &.active {
                    font-family: "gt-std-bold";
                    text-decoration: underline;
                }
            }
        }
        .menu-body {
            .main-navigation {
                width: 200px;
                margin-left: 80px;
                margin-top: 80px;
                li {
                    line-height: 1.5;
                    margin-bottom: 0px;
                    a {
                        color: #FFF;
                        font-family: "gt-mono";
                        font-size: 18px;
                        display: block;
                        transition: color 0.2s ease-in-out;
                        &:hover {
                            text-decoration: none;
                            color: #333;
                        }
                        span {
                            height: 42px;
                            display: inline-block;
                            padding-top: 7px;
                            padding-right: 20px;
                            margin-right: 20px;
                            text-transform: uppercase;
                        }
                        .num {
                            border-right: 1px solid #FFF;
                        }
                    }
                }
            }
        }
        .menu-footer {
            position: absolute;
            bottom: 30px;
            left: calc(50% - 100px);
            .search-img {
                width: 30px;
                cursor: pointer;
            }
            .search-input {
                height: 40px;
                width: 165px;
                border: 2px solid #FFF;
                background-color: $brand-brown;
                color: #FFF;
                padding-left: 15px;
            }
        }
    }
    &.filter-menu {
        background-color: #333;
        .menu-header {
            h2 {
                font-size: 36px;
            }
        }
        .menu-body {
            padding-top: 30%;
            .dropdown {
                .drop-btn {
                    margin-top: 5px;
                    margin-bottom: 35px;
                    box-shadow: none;
                    border-radius: 0px;
                    background-color: #333;
                    color: #FFF;
                    border: 2px solid #FFF;
                    width: 100%;
                    text-align: left;
                    text-transform: uppercase;
                    position: relative;
                    font-family: "gt-mono-bold";
                    cursor: pointer;
                    &:after {
                        position: absolute;
                        right: 15px;
                        top: 15px;
                    }
                }
            }
        }
        .menu-footer {
            position: absolute;
            bottom: 40px;
            left: calc(50% - 90px);
            .filter-btn {
                height: 40px;
                width: 180px;
                text-transform: uppercase;
                border: 2px solid #FFF;
                line-height: 40px;
                text-align: center;
                font-family: "gt-std-bold";
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                &:hover {
                    color: $brand-brown;
                    border-color: $brand-brown;
                }
            }
        }
    }
    &.active {
        right: 0px;
        box-shadow: 0 2px 8px 3px rgba(0,0,0,0.2);
    }
}
.window-opacity {
    height: 100vh;
    width: 100%;
    content: "";
    background-color: #000;
    opacity: 0;
    position: fixed;
    top: 0px;
    left: 0px;
    transition: opacity 0.2s ease-in-out;
    &.active {
        opacity: 0.4;
        transition: opacity 0.2s ease-in-out;
    }
    &.top-active {
        z-index: 1009;
    }
}
