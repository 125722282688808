.pagination {
    height: 125px;
}
.nav-links {
    margin: 0px auto;
    width: 200px;
    text-align: center;
    position: relative;
    a,
    span {
        color: $brand-gray;
        font-family: gt-mono;
    }
    .page-numbers {
        margin-right: 5px;
    }
    .current {
        font-family: gt-mono-bold;
    }
    .next {
        content: "";
        background-image: url("../../dist/images/svg/play.svg");
        background-repeat: no-repeat;
        background-size: 90%;
        height: 15px;
        width: 15px;
        filter: invert(20%);
        position: absolute;
        right: 0px;
        top: 5px;
    }
    .prev {
        content: "";
        background-image: url("../../dist/images/svg/play.svg");
        background-repeat: no-repeat;
        background-size: 90%;
        transform: rotate(180deg);
        height: 15px;
        width: 15px;
        filter: invert(20%);
        position: absolute;
        left: 0px;
        top: 5px;
    }
}
.post-type-archive-projekti footer.content-info .container-fluid {
    background-color: transparent;
}