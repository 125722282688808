.go-back-row {
    clear: both;
}
.go-back-button {
    display: inline-block;
    margin: 0 auto;
    text-transform: uppercase;
    font-family: "gt-mono-bold";
    height: 40px;
    padding-left: 30px;
    padding-right: 30px;
    color: #333333;
    background-color: #FFF;
    border: 2px solid #333333;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-top: 30px;
    line-height: 36px;
}
.go-back-button:hover {
    color: #998675;
    border: 2px solid #998675;
    text-decoration: none;
}
.go-back-button:focus,
.go-back-button:active {
    color: #FFF;
    background-color: #998675;
    border: 2px solid #998675;
}
