@include media-breakpoint-down(md) {
    .block-simple-halves.img-on-left {
        .text-col {
            order: 1;
        }
        .img-col {
            order: 2;
        }
    }
}
@include media-breakpoint-down(md) {
    .page {
        .block-bg-img__content {
            .block-bg-img__title {
                font-size: 56px;
            }
        }
    }
    .block-video {
        padding-top: 100px;
    }
}
@include media-breakpoint-down(sm) {
    .home {
        .container-fluid {
            .text-top {
                display: none;
            }
        }
    }
    .page {
        .block-bg-img__content {
            .block-bg-img__title {
                font-size: 48px;
            }
        }
        section.section.block-multi-col {
            .col-md-4 {
                margin-bottom: 20px;
            }
            .row .col-md-6:nth-child(2) {
                margin-top: 30px;
            }
        }
        section.section.img-on-left {
            .img-col svg {
                margin-bottom: 70px;
                margin-top: 0px;
            }
        }
        section.section.img-on-right {
            .img-col svg {
                margin-top: 70px;
                margin-bottom: 0px;
            }
        }
        section.block-simple-halves {
            .img-col {
                margin-top: 30px;
            }
        }
        &.gradnja {
            section.section.build-process {
                .columns-row {
                    .col-md-4 {
                        margin-top: 70px;
                        &:first-child {
                            margin-top: 0px;
                        }
                    }
                }
            }
        }
        &.about-us {
            .page-quote {
                span {
                    font-size: 48px;
                }
                .quote-mark {
                    width: 40px;
                }
            }
        }
        &.single-house {
            .section.house-details {
                .second-row {
                    padding-top: 50px;
                }
            }
        }
    }
    .single-projekti {
        .mobile-details {
            display: block;
        }
        .desktop-details {
            display: none;
        }
        .mobile-details {
            .accordion {
                font-size: 18px;
                display: block;
                padding: 10px 0px;
                border-bottom: 1px solid #FFF;
                .accordion-arrow {
                    filter: invert(100%);
                    transform: rotate(180deg);
                    transition: transform 0.2s ease-in-out;
                    width: 15px;
                    position: absolute;
                    right: 30px;
                }
                &.active {
                    .accordion-arrow {
                        transform: rotate(270deg);
                    }
                }
            }
            .accordion-panel {
                max-height: 0px;
                overflow: hidden;
                padding-top: 10px;
                transition: max-height 0.2s ease-in-out;
                span,
                a {
                    display: block;
                    font-size: 16px;
                    line-height: 2.5;
                    font-family: "gt-std";
                }
                a {
                    font-family: "gt-std-bold";
                    color: #FFF;
                    text-decoration: underline;
                    transition: color 0.2s ease-in-out;
                    &:hover {
                        color: $brand-brown;
                    }
                }
            }
        }
    }
    .double-title {
        margin-bottom: 45px;
    }
}
@include media-breakpoint-down(xs) {
    .page {
        .block-bg-img__content {
            .block-bg-img__title {
                font-size: 42px;
            }
        }
    }
    .about-us {
        .page-quote {
            span {
                font-size: 42px;
            }
            .quote-mark {
                width: 36px;
            }
        }
    }
    .single-projekti {
        .section.house-details .first-row {
            padding-top: 30px;
            .col-sm-4 {
                margin-bottom: 30px;
            }
        }
        .mobile-data {
            display:block;
            margin-bottom: 70px;
        }
    }
    .single-projekti .section.related-projects {
        .container {
            .row {
                width: auto;
                padding-right: 10px;
            }
        }
    }
    .category {
        .page-header {
            display: none;
        }
    }
    .hidden-xs-down {
        display: none;
    }
    .related-item:last-child {
        display: none;
    }
}
@media screen and (min-width: 550px) {
    .tel-icon {
        display: none;
    }
}
@media screen and (max-width: 550px) {
    span.text-bottom.left {
        display: none;
    }
    footer.content-info {
        .tel-num {
            display: none;
        }
        .footer-contact {
            .social-icon {
                display: none;
            }
        }
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    .align-items-end {
        .col-sm-4 {
            padding-left: 0px;
            .house-data {
                font-size: 16px;
                padding-left: 35px;
                line-height: 2.8;
            }
            &.hidden-xs-down {
                padding-right: 0px;
            }
        }
    }
    .single-projekti .section.related-projects {
        .container {
            .row {
                width: 104%;
            }
        }
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .single-projekti .section.related-projects {
        .container {
            .row {
                width: 102%;
            }
        }
    }
    .related-item:last-child {
        display: none;
    }
}
