.checkbox-box {
    padding-top: 30px;
    label.checkbox {
        margin-bottom: 10px;
        cursor: pointer;
    }
    .checkbox {
        user-select: none;
        display: block;
        position: relative;
        padding-left: 40px;
    }   
    .checkbox .square {
        width: 20px;
        height: 20px;
        border: solid 1px #FFF;
        display: inline-block;
        position: absolute;
        left: 0px;
        top: 3px;
        transition: all 0.2s ease-in-out;
        background-position: center;
        background-size: 0%;
    }

    .checkbox input[type="checkbox"] {
        display: none;
    }

    .checkbox input:checked + .square {
        background: url("../../dist/images/svg/check.svg") no-repeat;
        background-position: center;
        background-size: 60%;
    }
}