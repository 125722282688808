footer.content-info {
    position: absolute;
    bottom: 0px;
    width: 100%;
    .container-fluid {
        background-color: #FFF;
        height: 60px;
        .tel-num {
            float: left;
            line-height: 60px;
            margin-left: 20px;
            font-family: "gt-mono";
        }
        .tel-icon {
            filter: invert(75%);
            margin: 15px;
            display: inline-block;
            @media screen and (min-width: 550px) {
                display: none;
            }
        }
        .footer-contact {
            float: right;
            margin-right: 20px;
            a {
                line-height: 60px;
                font-family: "gt-mono";
                color: $brand-gray;
                text-decoration: none;
                .youtube {
                    opacity: 0.8;
                }
            }
            .social-icon {
                padding: 5px;
                margin-right: 10px;
            }
        }
    }
}
.home {
    footer.content-info {
        display: none;
    }
}
 /* Cookie - GDPR */
 i.moovegdpr-advanced {
     margin-right: 0px !important;
 }

 #moove_gdpr_save_popup_settings_button span.moove_gdpr_text {
     padding: 0px 20px !important;
 }

 .moovegdpr-advanced:before {
     /*content: url(https://klub-upokojencev.si/wp-content/uploads/2018/05/cookie_trnsparent-small.png) !important;*/
 }

 .moove-gdpr-branding-cnt {
     display: none !important;
 }
#moove_gdpr_cookie_info_bar {
     background-color: #333 !important;
}
 .moove-gdpr-info-bar-content {
     font-family: "gt-mono" !important;
 }
 .moove-gdpr-infobar-allow-all {
     border-radius: 0 !important;
     text-transform: uppercase !important;
     background-color: #333 !important;
     border: 2px solid #FFF !important;
 }
 .change-settings-button:hover {
     color: #FFF;
 }
 /* Cookie modal */
 .moove-gdpr-modal-content {
     border-radius: 0 !important;
 }
