.double-title {
    margin-bottom: 75px;
}

.double-title__sm {
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 1px;
    text-transform: uppercase;
}
