.blog,
.single-post,
.category {
    .main {
        max-width: 100%;
        padding: 0px;
        .page-header {
            position: absolute;
            @include media-breakpoint-down(lg) {
                position: fixed;
            }
            h1 {
                color: $brand-gray;
            }
        }
    }
}
.blog .page-header {
    h1:before {
        content: "04";
        margin-right: 15px;
    }
}
.post-list {
    padding-top: 100px;
    padding-bottom: 100px;
}
.post-list--item {
    margin-bottom: 35px;
    .img-col img {
        width: 100%;
        height: auto;
    }
    header {
        time {
            margin-bottom: 10px;
            font-size: 12px;
            line-height: 1.15;
            text-transform: uppercase;
        }
        h3 {
            margin-bottom: 25px;
            a {
                color: $brand-gray;
            }
        }
    }
}
.widgettitle,
.cat-item-1 {
    display: none;
}
