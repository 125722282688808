body .page-header {
    font-family: "gt-mono";
    h1 {
        color: #FFF;
        transition: color 0.2s ease-in-out;
    }
    &.mobile-active {
        h1 {
            color: #333;
        }
    }
}

header.banner {
    nav.fixed {
        z-index: 2;
        .midnightHeader {
            .logo {
                font-size: 27px;
                font-family: "Alfa";
                text-decoration: none;
                margin-left: 15px;
                margin-top: 8px;
                display: inline-block;
            }
            .header-menu {
                z-index: 1;
                cursor: pointer;
                font-size: 27px;
                font-family: "Alfa";
                float: right;
                margin-right: 15px;
                margin-top: 8px;
            }
            &.default {
                .logo,
                .header-menu {
                    color: #FFF;
                }
            }
            &.white {
                .logo,
                .header-menu {
                    color: #333;
                }
            }
        }
    }
    nav.fixed-mobile {
        z-index: 2;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 60px;
        -webkit-box-shadow: 0px 3px 10px -3px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 3px 10px -3px rgba(0,0,0,0);
        box-shadow: 0px 3px 10px -3px rgba(0,0,0,0);
        transition: all 0.2s ease-in-out;
        background-color: transparent;

        &.menu-down {
            top: 0px;
            background-color: #FFF;
            -webkit-box-shadow: 0px 3px 10px -3px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 3px 10px -3px rgba(0,0,0,0.75);
            box-shadow: 0px 3px 10px -3px rgba(0,0,0,0.75);
            .logo,
            .header-menu {
                color: #333;
            }
        }
        .logo {
            font-size: 24px;
            font-family: "Alfa";
            text-decoration: none;
            margin-left: 15px;
            margin-top: 10px;
            display: inline-block;
            color: #FFF;
            transition: all 0.2s ease-in-out;
        }
        .header-menu {
            z-index: 1;
            cursor: pointer;
            font-size: 27px;
            font-family: "Alfa";
            float: right;
            margin-right: 15px;
            margin-top: 8px;
            color: #FFF;
            transition: all 0.2s ease-in-out;
        }

    }
}
.about-us,
.archive,
.blog {
    header.banner nav.fixed-mobile {
        .logo,
        .header-menu {
            color: #333;
        }
    }
}
@media screen and (min-width: 1250px) {
    header.mobile-banner {
        display: none;
    }
}
@media screen and (max-width: 1251px) {
    body .page-header {
        z-index: 1009;
    }
    header.banner {
        display: none;
    }
    header.mobile-banner {
        display: block;
        nav.fixed {
            a,
            span {
            }
        }
    }
}
