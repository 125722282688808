.text-top {
    position: absolute;
    top: 15px;
    left: 0px;
    z-index: 1;
    text-align: center;
    width: 100%;
    span {
        display: inline-block;
        position: relative;
        font-family: "gt-std";
        &:first-child {
            margin-right: 10px;
            font-family: "gt-std-bold";
            &:after {
                height: 13px;
                width: 1px;
                border-left: 1px solid #333;
                content: " ";
                position: absolute;
                right: -8px;
                bottom: 5px;
            }
        }
    }
    @include media-breakpoint-down(xs) {
        display: none;
    }
}
.page-header {
    position: fixed;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    h1 {
        font-size: 18px;
        color: #FFF;
        text-transform: uppercase;
    }
}
.archive .page-header {
    position: absolute;
    @include media-breakpoint-down(lg) {
        position: fixed;
    }
}
.page {
    .block-bg-img.top-banner {
        position: fixed;
        top: 0px;
        z-index: -2;
    }
    .block-bg-img__content {
        padding-top: 140px;
        .block-bg-img__title {
            font-size: 64px;
            line-height: 1.2;
            color: #FFF;
            font-family: "gt-std";
        }
    }
    &.material,
    &.material-test {
        .page-header {
            h1:before {
                content: "01";
                margin-right: 15px;
            }
        }
        .top-banner {
            background: url("../../dist/images/jpg/material.jpg") no-repeat;
            background-size: cover;
            background-position: center;
            width: 100%;
        }
        section.section {
            min-height: 550px;
            &.block-multi-col {
                padding-top: 100px;
                padding-bottom: 100px;
                ul {
                    line-height: 2.3;
                }
            }
        }
    }
    &.gradnja,
    &.gradnja-test {
        .page-header {
            h1:before {
                content: "02";
                margin-right: 15px;
            }
        }
        .top-banner {
            background: url("../../dist/images/jpg/gradnja.jpg") no-repeat;
            background-size: cover;
            background-position: center;
            width: 100%;
            height: 100%;
        }
        section.section {
            &.block-multi-col {
                min-height: 500px;
                padding-top: 100px;
                padding-bottom: 100px;
            }
            &.build-process-cols {
                .block-multi-col__col-title {
                    font-size: 24px;
                    margin-bottom: 30px;
                    font-family: "gt-std";
                    font-weight: normal;
                }
                .col-md-4 {
                    margin-top: 35px;
                    &:first-child {
                        @include media-breakpoint-down(sm) {
                            margin-top: 0px;
                        }
                    }
                }
            }
        }
    }
    &.about-us {
        .page-quote {
            padding-top: 100px;
            .quote-mark {
                width: 55px;
                filter: invert(20%);
            }
            span {
                font-size: 64px;
                line-height: 1.2;
                font-family: "gt-std";
                display: block;
            }
        }
        .team-an {
            padding-top: 150px;
            .team-intro,
            .team-person {
                margin-bottom: 100px;
                @include media-breakpoint-down(xs) {
                    margin-bottom: 65px;
                }

            }
            .team-intro {
                font-family: "gt-std";
                span {
                    font-size: 12px;
                    text-transform: uppercase;
                    display: block;
                    margin-bottom: 10px;
                }
                h2 {
                    margin-bottom: 40px;
                }
                p {
                    margin-bottom: 25px;
                }
                .follow-us {
                    span {
                        margin-bottom: 15px;
                    }
                    .social {
                        display: inline-block;
                        margin-right: 20px;
                        &.twitter {
                            width: 30px;
                            opacity: 0.8;
                        }
                        &.pinterest {
                            width: 20px;
                        }
                        &.facebook {
                            width: 13px;
                        }
                    }
                }
            }
            .team-person {
                .person-img {
                    width: 100%;
                    margin-bottom: 30px;
                }
                .person-data {
                    span {
                        display: block;
                    }
                    .name {
                        font-size: 18px;
                        line-height: 1.5;
                        margin-bottom: 10px;
                        font-family: "gt-std-bold";
                    }
                    .title {
                        font-size: 12px;
                        text-transform: uppercase;
                        margin-bottom: 15px;
                        font-family: "gt-std";
                    }
                    .social-links {
                        .social {
                            display: inline-block;
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
        .section.co-workers {
            background-color: $brand-gray;
            color: #FFF;
            .container {
                padding-top: 100px;
                .above-title {
                    font-size: 12px;
                    text-transform: uppercase;
                    display: block;
                    margin-bottom: 15px;
                    font-family: "gt-std";
                }
                .co-team-an {
                    padding-top: 0px;
                    .person-data {
                        .social-links {
                            .social {
                                filter: invert(100%);
                            }
                        }
                    }
                }
            }
        }
    }
    .section.contact-us {
        height: 500px;
        background-size: cover;
        background-position: center;
        .block-bg-img__content {
            padding-top: 100px;
            .block-bg-img__btn {
                width: 260px;
                text-align: center;
                border: 2px solid #FFF;
                line-height: 40px;
                margin-top: 70px;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                display: block;
                text-decoration: none;
                span {
                    text-transform: uppercase;
                    font-family: "gt-mono-bold";
                    color: #FFF;
                }
            }
        }
    }
}

.filter {
    background-color: $brand-gray;
    border-radius: 50%;
    padding: 5px;
    position: fixed;
    bottom: 55px;
    right: 25px;
    cursor: pointer;
    z-index: 1;
}
.archive {
    .page-header {
        h1 {
            color: $brand-gray;
            &:before {
                content: "03";
                margin-right: 15px;
            }
        }
    }
    &.category {
        .page-header {
            h1 {
                color: $brand-gray;
                &:before {
                    content: "";
                    margin-right: 0px;
                }
            }
        }
    }
}
.section.project-list {
    padding-top: 100px;
    .list-item {
        margin-bottom: 75px;
        position: relative;
        a {
            text-decoration: none;
        }
        .item-img {
            width: 100%;
            transition: all 0.2s ease-in-out;
        }
        span {
            font-family: "gt-std";
            display: block;
            &.item-title {
                font-size: 18px;
                color: $brand-gray;
            }
            &.item-details {
                font-size: 12px;
                color: $brand-brown;
            }
        }
        .social-icon-hover {
            position: absolute;
            width: 50px;
            height: 100%;
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            @media (min-width: 768px) and (max-width: 1199px) {
                top: 42%;
            }
        }
        .hover-icon {
            position: absolute;
            opacity: 0px;
            transition: all 0.2s ease-in-out;
            &.search {
                top: 32%;
                transform: translateY(-50%);
                left: 50%;
                transform: translateX(-50%);
                @media (min-width: 768px) and (max-width: 1199px) {
                    top: 27%;
                }
            }
            &.twitter {
                top: 35px;
                right: 30px;
                filter: invert(100%);
            }
            &.facebook {
                top: 66px;
                right: 34px;
                filter: invert(100%);
            }
            &.pinterest {
                top: 100px;
                right: 32px;
                filter: invert(100%);
            }
        }
        &:hover {
            .hover-icon {
                display: block;
                opacity: 1;
            }
            .item-img {
                filter: brightness(30%);
                opacity: 0.8;
            }
        }
    }
}
.section.contact-info {
    padding: 100px 0px 150px 0px;
    .contact-info__text {
        margin-bottom: 35px;
        h2 {
            margin-bottom: 35px;
        }
    }
    .contact-info__map {
        iframe {
            border: none;
            width: 100%;
        }
    }
    #map {
        width: 100%;
        height: 400px;
    }
}
.single-projekti {
    .main {
        padding-left: 0;
        padding-right: 0;
    }
    .text-top {
        color: #FFF;
        span {
            &:after {
                border-color: #FFF;
            }
        }
    }
    .house-img {
        position: fixed;
        width: 100%;
        height: 100vh;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        box-shadow: inset 0px -250px 250px -50px rgba(0,0,0,0.7);
        top: 0;
        left: 0;
        z-index: -1;
        .align-items-end {
            height: 100vh;
            color: #FFF;
            padding-bottom: 50px;
            h3 {
                text-transform: uppercase;
                font-size: 48px;
                line-height: 1.25;
                font-family: "gt-mono";
            }
        }
    }
    .house-data {
        display: block;
        font-size: 18px;
        line-height: 2.3;
        padding-left: 50px;
        position: relative;
        font-family: "gt-std";
        &:before {
            position: absolute;
            left: 0px;
            top: 10px;
            content: "";
            display: block;
            height: 25px;
            width: 25px;
            background-repeat: no-repeat;
        }
        &.year:before {
            background-image: url("../../dist/images/svg/plosce.svg");
        }
        &.dimension:before {
            background-image: url("../../dist/images/svg/velikost.svg");
        }
        &.size:before {
            background-image: url("../../dist/images/svg/box.svg");
        }
        &.location:before {
            background-image: url("../../dist/images/svg/lokacija.svg");
        }
        &.floors:before {
            background-image: url("../../dist/images/svg/nadstropja.svg");
        }
        &.time:before {
            background-image: url("../../dist/images/svg/ura.svg");
        }
    }
    .mobile-data,
    .mobile-details {
        display: none;
    }
    .section.house-details {
        margin-top: 100vh;
        background-color: $brand-gray;
        color: #FFF;
        .first-row {
            padding-top: 80px;
            .col-md-4 {
                margin-bottom: 30px;
            }
            .detail-header {
                span {
                    display: block;
                }
                .d-eyecatch {
                    font-size: 18px;
                    font-style: italic;
                    > b {
                        display: block;
                        font-size: 48px;
                        font-family: "gt-mono";
                        font-weight: normal;
                        font-style: normal;
                    }
                }
                .d-title {
                    font-size: 12px;
                    text-transform: uppercase;
                    font-family: "gt-std";
                }
            }
            .detail-content {
                padding-top: 50px;
                span,
                a {
                    display: block;
                    font-size: 18px;
                    line-height: 2.3;
                    font-family: "gt-std";
                }
                a {
                    text-decoration: underline;
                    font-family: "gt-std-bold";
                    color: #FFF;
                    transition: color 0.2s ease-in-out;
                    &:hover {
                        color: $brand-brown;
                    }
                }
            }
            @include media-breakpoint-down(md) {
                .detail-header .d-eyecatch {
                    font-size: 16px;
                    > b {
                        font-size: 42px;
                    }
                }
                .detail-content {
                    padding-top: 35px;
                    a,
                    span {
                        font-size: 16px;
                    }
                }
            }
        }
        .second-row {
            padding-top: 150px;
            padding-bottom: 100px;
            p {
                margin-bottom: 35px;
            }
            a {
                color: #FFF;
                text-decoration: none;
            }
            .button {
                width: 260px;
                text-align: center;
                border: 2px solid #FFF;
                line-height: 40px;
                margin-top: 70px;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                font-family: "gt-std-bold";
                line-height: 40px;
                display: block;
                &:hover {
                    border-color: $brand-brown;
                    color: $brand-brown;
                }
                span {
                    text-transform: uppercase;
                    font-family: "gt-mono-bold";
                }
            }
            .img-col {
                text-align: center;
                @include media-breakpoint-down(sm) {
                    margin-top: 70px;
                }
                img {
                    filter: invert(100%);
                    width: 70%;
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
    .section.related-projects {
        padding: 100px 0px;
        background-color: #FFF;
        .container {
            .row {
                padding-left: 10px;
                width: 101%;
            }
            h2 {
                font-size: 36px;
                margin-bottom: 60px;
                font-family: "gt-std";
                padding-left: 10px;
            }
        }
    }
}
.material,
.material-test,
.gradnja,
.gradnja-test {
    .not-main {
        .section:nth-child(3) {
            margin-top: 500px;
        }
    }
}
.block-simple-halves {
    padding-bottom: 100px;
    padding-top: 100px;
    .text-col {
        font-family: "gt-std";
    }
    .img-col {
        text-align: center;
        img {
            max-width: 100%;
        }
    }
}
.block-gallery-halves {
    background-color: #FFF;
    padding-top: 100px;
    padding-bottom: 70px;
    .text-col {
        font-family: "gt-std";
        ol {
            margin-top: 75px;
            padding-left: 0px;
            counter-reset: item;
            li {
                display: block;
                &:before {
                    content: counter(item) ".\00a0 \00a0";
                    counter-increment: item;
                    font-weight: bold;
                }
            }
        }
    }
    .img-col {
        margin-top: 20px;
        .nav-pills {
            li {
                a {
                    color: #333;
                    margin-right: 24px;
                    text-decoration: none;
                    transition: color 0.2s ease-in-out;
                    font-family: "gt-std-bold";
                    &.active {
                        color: $brand-brown;
                    }
                    &:hover {
                        color: $brand-brown;
                        text-decoration: underline;
                    }
                }
            }
        }
        .tab-content {
            .tab-pane {
                img {
                    max-width: 100%;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 50px;
                }
            }
        }
    }
}
