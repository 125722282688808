.gallery {
    margin-top: 50px;
    .gallery-item {
        margin: 0px 15px 15px 0px;
        float: left;
    }
}

.pswp__bg {
    background: $brand-gray;
}
.pswp__top-bar {
    background-color: $brand-gray !important;
}
.pswp__button--arrow--right::before,
.pswp__button--arrow--left::before {
    background-color: $brand-gray !important;
}

.pswp__button--close {
    background-image: url("../../dist/images/svg/close.svg");
}