// Helper classes to apply extra margins to elements

.offset-top-parallax { margin-top: 500px !important; }

// Follow the pattern to add more
// .offset-<top\right\bottom\left\horizontal\vertical>-<sm\md\lg>

.offset-vertical-md {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
}
