.home {
    background: #333;
    height: 100vh;
    header nav,
    .text-bottom,
    .tel-icon {
        z-index: 1001 !important;
    }
    .text-top {
        z-index: 1000 !important;
    }
    .wrap {
        .main {
            .container-fluid {
                .banner-container {
                    height: 100vh;
                    width: 100%;
                    position: fixed;
                    bottom: 0px;
                    overflow: hidden;
                    &.screen-1 {
                        height: 0vh;
                        -webkit-transition: height 1s cubic-bezier(.87,0,.61,.96);
                        -moz-transition: height 1s cubic-bezier(.87,0,.61,.96);
                        -o-transition: height 1s cubic-bezier(.87,0,.61,.96);
                        transition: height 1s cubic-bezier(.87,0,.61,.96);
                        border-top: 1px solid #000;
                        &.active {
                            height: 100vh;
                        }
                        .home-banner {
                            background: url("../../dist/images/home-3a.jpg") no-repeat;
                            background-position: center;
                            background-size: cover;
                            /*&:before {
                                background: url("../../dist/images/home-1-gif.gif") no-repeat;
                                background-position: center;
                                background-size: cover;
                            }*/
                        }
                    }
                    &.screen-2 {
                        height: 0vh;
                        -webkit-transition: height 1s cubic-bezier(.87,0,.61,.96);
                        -moz-transition: height 1s cubic-bezier(.87,0,.61,.96);
                        -o-transition: height 1s cubic-bezier(.87,0,.61,.96);
                        transition: height 1s cubic-bezier(.87,0,.61,.96);
                        border-top: 1px solid #000;
                        &.active {
                            height: 100vh;
                        }
                        .home-banner {
                            background: url("../../dist/images/home-1b.jpg") no-repeat;
                            background-position: center;
                            background-size: cover;
                            /*&:before {
                                background: url("../../dist/images/home-2-gif.gif") no-repeat;
                                background-position: center;
                                background-size: cover;
                            }*/
                        }
                    }
                    &.screen-3 {
                        height: 0vh;
                        -webkit-transition: height 1s cubic-bezier(.87,0,.61,.96);
                        -moz-transition: height 1s cubic-bezier(.87,0,.61,.96);
                        -o-transition: height 1s cubic-bezier(.87,0,.61,.96);
                        transition: height 1s cubic-bezier(.87,0,.61,.96);
                        border-top: 1px solid #000;
                        &.active {
                            height: 100vh;
                        }
                        .home-banner {
                            background: url("../../dist/images/home-2a.jpg") no-repeat;
                            background-position: center;
                            background-size: cover;
                        }
                    }
                    .home-banner {
                        height: 100vh;
                        width: 100%;
                        position: absolute;
                        bottom: 0px;
                        &:before {
                            content: " ";
                            height: 100vh;
                            width: 100%;
                            display: block;
                        }
                        .page-numbers {
                            position: absolute;
                            left: 20px;
                            top: 50%;
                            transform: translateY(-50%);
                            z-index: 999;
                            span {
                                display: block;
                                font-family: "gt-mono";
                                font-size: 27px;
                                color: rgba(255, 255, 255, 0.3);
                                margin-bottom: 30px;
                                position: relative;
                                transition: color 0.2s ease-in-out;
                                cursor: pointer;
                                &:last-child {
                                    margin-bottom: 0px;
                                }
                                &:before {
                                    display: block;
                                    position: absolute;
                                    width: 60px;
                                    height: 1px;
                                    top: 0px;
                                    left: -80px;
                                    content: "";
                                    border-top: 1px solid #FFF;
                                    transition: left 0.2s ease-in-out;
                                }
                                &.active {
                                    color: rgba(255, 255, 255, 1);
                                    &:before {
                                        left: -20px;
                                    }
                                }
                            }
                            @include media-breakpoint-down(sm) {
                                left: 13px;
                                span {
                                    font-size: 24px;
                                    margin-bottom: 25px;
                                    &:before {
                                        width: 45px;
                                        left: -58px;
                                    }
                                    &.active {
                                        &:before {
                                            left: -13px;
                                        }
                                    }
                                }
                            }
                        }
                        .text-center {
                            position: absolute;
                            left: 0px;
                            top: 50%;
                            transform: translateY(-50%);
                            font-size: 64px;
                            color: #FFF;
                            width: 100%;
                            .page-num {
                                display: block;
                                font-family: "gt-mono";
                            }
                            .page-text {
                                font-family: "gt-std";
                                display: block;
                                max-width: 70%;
                                margin: 0px auto;
                            }
                            @include media-breakpoint-down(sm) {
                                top: 50%;
                                font-size: 36px;
                                .page-num {
                                    position: static;
                                    display: block;
                                    width: 200px;
                                    margin: 0px auto;
                                    border-top: none;
                                    border-bottom: 1px solid #FFF;
                                    padding-left: 0px;
                                    font-size: 64px;
                                }
                                .page-text {
                                    line-height: 1.2;
                                }
                            }
                        }
                        .banner-button {
                            height: 40px;
                            width: 180px;
                            position: absolute;
                            bottom: 35px;
                            left: calc(50% - 90px);
                            background-color: #FFF;
                            text-align: center;
                            box-shadow: -1px 1px 5px 0 rgba(0,0,0,0.5);
                            cursor: pointer;
                            text-decoration: none;
                            @include media-breakpoint-down(sm) {
                                bottom: 60px;
                            }
                            span {
                                line-height: 40px;
                                text-transform: uppercase;
                                color: $brand-gray;
                                font-family: "gt-mono-bold";
                                transition: color 0.2s ease-in-out;
                            }
                            &:hover {
                                span {
                                    color: $brand-brown;
                                }
                            }
                        }
                    }
                }
                .text-top {
                    color: #FFF;
                    overflow: hidden;
                    span {
                        display: inline-block;
                        position: relative;
                        font-family: "gt-std";
                        &:first-child {
                            margin-right: 10px;
                            font-family: "gt-std-bold";
                            &:after {
                                height: 13px;
                                width: 1px;
                                border-left: 1px solid #FFF;
                                content: " ";
                                position: absolute;
                                right: -8px;
                                bottom: 5px;
                            }
                        }
                    }
                }
                .text-bottom,
                .tel-icon {
                    position: fixed;
                    bottom: 20px;
                    color: #FFF;
                    font-family: "gt-mono";
                    &.left {
                        left: 20px;
                    }
                    &.right {
                        right: 20px;
                    }
                }
                .tel-icon {
                    bottom: 15px;
                }
            }
        }
    }
}
/*.video-box {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    .video-background {
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
    }
}*/

.video-background {
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background-size: cover;
    @media screen and (orientation:portrait) {
        width: auto;
        height: 103%;
    }
}
.home-shadow {
    position: absolute;
    width: 100%;
    height: 100vh;
    box-shadow: inset 0px -150px 150px -70px rgba(0,0,0,0.75);
    top: 0px;
    left: 0px;
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100vh;
        background-color: rgba(0,0,0,0.15);
        top: 0px;
        left: 0px;
    }
}
.home-shadow-2 {
    position: absolute;
    width: 100%;
    height: 100vh;
    box-shadow: inset 0px 100px 150px -70px rgba(0,0,0,0.75);
    top: 0px;
    left: 0px;
}
@media (min-width: 768px) and (max-width: 992px) {
    /*.home-long-text {
        display: inline-block;
        width: 500px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }
    .home-long-text-num {
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }*/
}
@media screen and (max-width: 430px) {
    .home-long-text {
        display: inline-block;
        width: 320px;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}
