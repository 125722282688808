// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

//Page colors
$brand-gray: #333333;
$brand-brown: #998675;


// GT-America fonts
@font-face {
    font-family: "gt-std";
    font-weight: normal;
    src: url(../../dist/fonts/GT-America-Standard-Regular.woff) format("truetype");
}
@font-face {
    font-family: "gt-std-bold";
    font-weight: bold;
    src: url(../../dist/fonts/GT-America-Standard-Bold.woff) format("truetype");
}
@font-face {
    font-family: "gt-mono";
    font-weight: normal;
    src: url(../../dist/fonts/GT-America-Mono-Regular.woff) format("truetype");
}
@font-face {
    font-family: "gt-mono-bold";
    font-weight: bold;
    src: url(../../dist/fonts/GT-America-Mono-Bold.woff) format("truetype");
}
@font-face {
    font-family: "Alfa";
    src: url("../../dist/fonts/alfanaturaicons-Regular.otf") format("opentype");
}
